import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function AboutUs() {
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
               
                <Typography 
                    top = '50px'
                    position = 'relative'
                    align="left"
                    marginTop="3%"
                    marginBottom="2%"
                    marginLeft= '3%'
                    sx={{
                        color: '#333333',
                        fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 40px)' },
                        padding: { xs: '2rem 0', md: '2rem' },
                    }}
                >
                    About Us
                </Typography>

                <Typography 
                    align="left"
                    sx={{
                        marginTop: {xs: "6%", md: "3%"},
                        paddingLeft: '8%',
                        paddingRight: '8%',
                        color: "#333333",
                        fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 20px)' }
                    }}
                >
                    ZL International is a manufacturer and distributor, specializing in the supply & logistics of a variety of packaging solutions from China.  Backed by decades of experience, ZL offers customers complete solutions for their packaging needs by providing value added services and support.
Founded by James Wang in 1997, the company has a strong customer focus helping customers identify and implement engineering solutions from the premier supply sources in China.  Our primary focus is supplying turnkey solutions for IML labels, molds and automation.  We also supply thermoformed parts made in China.  
ZL is uniquely equipped to offer robust engineering solutions from China for the packaging market.

                </Typography>

                <div>
                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '8%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 3vw, 25px)', md: 'clamp(8px, 10vw, 30px)' }
                        }}
                    >
                        Our Mission
                    </Typography>

                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"1%",
                            paddingLeft: '12%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 20px)' }
                        }}
                    >
                        To drive design solutions from China focused on molding and IML labeling of molded plastic parts.
                    </Typography>
                </div>

                <div>
                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '8%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 3vw, 25px)', md: 'clamp(8px, 10vw, 30px)' }
                        }}
                    >
                        Our Vision
                    </Typography>

                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"1%",
                            paddingLeft: '12%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 20px)' }
                        }}
                    >
                        To become a recognized leader as a supplier of Molds, Automation and IML labels from China.
                    </Typography>
                </div>

                <div>
                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '8%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 3vw, 25px)', md: 'clamp(8px, 10vw, 30px)' }
                        }}
                    >
                        Our Values
                    </Typography>

                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"1%",
                            paddingLeft: '12%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 20px)' }
                        }}
                    >
                        
                        <p>Customer focus at all times</p>
                        <p>Quality in our work</p>
                        <p>Cooperation with our business partners</p>
                    </Typography>
                </div>

                <div>
                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '8%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 3vw, 25px)', md: 'clamp(8px, 10vw, 30px)' }
                        }}
                    >
                        Our Advantages
                    </Typography>

                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"1%",
                            paddingLeft: '12%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 20px)' }
                        }}
                    >
                        
                        As a global company, ZL leverages its international supplier partnerships to ensure customers get the products they need, when they need it.
By partnering with a distribution leader, manufactures benefit from Design, Supply and Production.
                    </Typography>
                </div>

                <div>
                    <Typography 
                        align="left"
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '8%',
                            paddingRight: '8%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 3vw, 25px)', md: 'clamp(8px, 10vw, 30px)' }
                        }}
                    >
                        Our Services
                    </Typography>

                    <Typography 
                        align="left"
                        paddingBottom= '5%'
                        sx={{
                            paddingTop:"1%",
                            paddingLeft: '12%',
                            paddingRight: '8%',
                            
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 20px)' }
                        }}
                    >
                        Free installation, on-site training, after-sell services, insurance covered.
                    </Typography>
                </div>
            </Box>
            </ThemeProvider>
        </div>
    );
}
