import React, { Component } from "react";
import { BrowserRouter, Router, Switch, Route, Redirect, HashRouter } from "react-router-dom";

import AboutUs from "./AboutUs";
import Contact from "./Contact";
import HomePage from "./HomePage";
import IMLAuto from "./products/IML_auto";
import IMLLabel from "./products/IML_label";
import AluminumTubes from "./products/aluminum_tubes";
import PaperPackaging from "./products/paper_packaging";
import BlowMolding from "./products/blow_molding";
import InjectionMolding from "./products/injection_molding";
import PlasticTubes from "./products/plastic_tubes";
import ThermalForming from "./products/thermal_forming";
import GlassPackaging from "./products/glass_packaging";



import history from './history';
import ScrollToTop from './ScrollToTop';

export default class Routes extends Component {
    
    render() {
        const basename = '/zlintl'; // replace with your sub-URL
        return (
            
             <Router 
             history={history}
             basename={basename}
             >
                <ScrollToTop />
                    <Switch>
                        {/* <Route
                            exact
                            path="#"
                            render={() => {
                                return (
                                <Redirect to="#Home" /> 
                                )
                            }}
                        /> */}
                        <Route
                            exact
                            path="/"
                            component={HomePage}
                        />
                        <Route path="/Home" component={HomePage} />
                        <Route path="/AboutUs" component={AboutUs} />
                        <Route path="/Contact" component={Contact} />
                        <Route path="/IML_auto" component={IMLAuto} />
                        <Route path="/IML_label" component={IMLLabel} />
                        <Route path="/aluminum_tubes" component={AluminumTubes} />
                        <Route path="/paper_pack" component={PaperPackaging} />
                        <Route path="/blow_molding" component={BlowMolding} />
                        <Route path="/injection_molding" component={InjectionMolding} />
                        <Route path="/plastic_tubes" component={PlasticTubes} />
                        <Route path="/thermal_forming" component={ThermalForming} />
                        <Route path="/glass_packaging" component={GlassPackaging} />
                    </Switch>
            </Router>

            // // <BrowserRouter>
            // <div>
            //     <ScrollToTop />
            //         <Switch>
            //             {/* <Route
            //                 exact
            //                 path="#"
            //                 render={() => {
            //                     return (
            //                     <Redirect to="#Home" /> 
            //                     )
            //                 }}
            //             /> */}
            //             <Route
            //                 exact
            //                 path="/"
            //                 component={HomePage}
            //             />
            //             <Route path="/Home" component={HomePage} />
            //             <Route path="/AboutUs" component={AboutUs} />
            //             <Route path="/Contact" component={Contact} />
            //             <Route path="/IML_auto" component={IMLAuto} />
            //             <Route path="/IML_label" component={IMLLabel} />
            //             <Route path="/aluminum_tubes" component={AluminumTubes} />
            //             <Route path="/paper_pack" component={PaperPackaging} />
            //             <Route path="/blow_molding" component={BlowMolding} />
            //             <Route path="/injection_molding" component={InjectionMolding} />
            //             <Route path="/plastic_tubes" component={PlasticTubes} />
            //             <Route path="/thermal_forming" component={ThermalForming} />
            //             <Route path="/glass_packaging" component={GlassPackaging} />
            //         </Switch>
            // {/* </BrowserRouter> */}
            // </div>
        )
    }
}