import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonMailto from './EmailLink';

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function Contact() {
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                    top = '50px'
                    position = 'relative'
                    align="left"
                    marginTop="3%"
                    marginBottom="2%"
                    marginLeft= '3%'
                    sx={{
                        color: '#333333',
                        fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 40px)' },
                        padding: { xs: '2rem 0', md: '2rem' },
                    }}
                >
                    Contact Us
                </Typography>

                <Typography 
                    align="left"
                    sx={{
                        marginTop: {xs: "10%", md: "3%"},
                        paddingLeft: '8%',
                        paddingRight: '8%',
                        color: "#333333",
                        fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' }
                    }}
                >
                    If you have any questions regarding our products or services, please contact us by phone or by email and we’ll get back to you as soon as possible.
                </Typography>

                <Typography 
                    align="left"
                    sx={{
                        paddingTop:"3%",
                        paddingLeft: '8%',
                        paddingRight: '8%',
                        color: "#333333",
                        fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 20px)' }
                    }}
                >
                    Email: 
                    <ButtonMailto label="jimwang@zlintl.com" mailto="mailto:jimwang@zlintl.com" />
                </Typography>

                
            </Box>
            </ThemeProvider>
        </div>
    );
}
