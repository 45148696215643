import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ZL_Logo from './ZL-logo copy transparent.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import history from './history';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Router, Switch, Route, Redirect, HashRouter, Link } from "react-router-dom";

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });


export default function Header() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const dropDownOpen = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseIMLAuto = (event) => {
        history.push('/IML_auto');
        setAnchorEl(null);
    };

    const handleCloseIMLLabel = (event) => {
        history.push('/IML_label');
        setAnchorEl(null);
    };

    const handleCloseAluminumTubes = (event) => {
        history.push('/aluminum_tubes');
        setAnchorEl(null);
    };

    const handleClosePaperPackaging = (event) => {
        history.push('/paper_pack');
        setAnchorEl(null);
    };
    
    const handleCloseBlowMolding = (event) => {
        history.push('/blow_molding');
        setAnchorEl(null);
    };

    const handleCloseInjectionMolding = (event) => {
        history.push('/injection_molding');
        setAnchorEl(null);
    };

    const handleClosePlasticTubes = (event) => {
        history.push('/plastic_tubes');
        setAnchorEl(null);
    };

    const handleCloseThermalForming = (event) => {
        history.push('/thermal_forming');
        setAnchorEl(null);
    };

    const handleCloseGlassPackaging = (event) => {
        history.push('/glass_packaging');
        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={theme}>
        <Box>
        <AppBar 
            position="fixed"
        >
            <Toolbar
                style={{ 
                    background: '#e9a17b',
                    height: '5vh'
                }}
            >
                <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
              >
            <Button
                size="small"
                sx={{
                    left: '-8%',
                    marginRight: '15%',
                  }}
                  onClick={() => history.push('/Home')}
                disableRipple={true}
            >
                
                <img src={ZL_Logo} alt="ZL logo" 
                    width='50%'
                    height='50%'
                />
                
            </Button>

                <Button 
                    size="small"
                    sx={{
                        // marginLeft: '23%',
                        // marginRight: '2%',
                        color:"#444444",
                        fontWeight: 'bold',
                    }}
                    onClick={() => history.push('/Home')}
                    >
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Home
                        </Typography>
                </Button>

                {/* <Link
                    className="temp"
                    activeClassName="active"
                    to="/Home"
                >
                    Home
                </Link>

                <Link
                    className="temp2"
                    activeClassName="active2"
                    to="/AboutUs"
                >
                    AboutUs
                </Link> */}

                <Button 
                    color="inherit" 
                    size="small" 
                    sx={{
                        // marginLeft: '2%',
                        // marginRight: '2%',
                        color:"#444444",
                        fontWeight: 'bold'
                    }}
                    id="basic-button"
                    aria-controls={dropDownOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={dropDownOpen ? 'true' : undefined}
                    onClick={handleClick}
                    >
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Products
                        </Typography>
                        {dropDownOpen ? <KeyboardArrowUpIcon sx={{
                                    height: { xs: '2vw', md: '1.5vw' },
                                    width: { xs: '2vw', md: '1.5vw' },
                                }}/>: <KeyboardArrowDownIcon sx={{
                                    height: { xs: '2vw', md: '1.5vw' },
                                    width: { xs: '2vw', md: '1.5vw' },
                                }}/>}
                </Button>
                
                    
                

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={dropDownOpen}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                        '& .MuiMenu-paper': {
                        top: '30px', // adjust the top position to avoid overlap with the Toolbar
                        right: '0px',
                        width: {xs: '50%', md: '25%'}, // make the menu width full width
                        maxHeight: 'calc(100vh - 60px)', // set max-height to avoid overflow
                        overflowY: 'auto', // add scrollbar if needed
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // center menu items vertically
                        padding: '20px 0', // add padding for better look and feel
                        backgroundColor: '#f5f5f5' // change background color
                        }
                    }}
                >
                    <MenuItem onClick={handleCloseIMLAuto}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            IML Automation and Molds
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseIMLLabel}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            IML Labels and Containers
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseAluminumTubes}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Aluminum Tubes
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClosePlasticTubes}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Plastic Tubes
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseInjectionMolding}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Injection Molding Packaging Materials
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseBlowMolding}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Blow Molding Packaging Materials
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseThermalForming}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Thermal Forming Containers
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClosePaperPackaging}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Paper Packaging Materials
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseGlassPackaging}>
                        
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Glass Packaging Materials
                        </Typography>
                    </MenuItem>
                </Menu>

                <Button 
                    color="inherit" 
                    size="small" 
                    sx={{
                        // marginLeft: '2%',
                        // marginRight: '2%',
                        color:"#444444",
                        fontWeight: 'bold'
                    }}
                    onClick={() => history.push('/AboutUs')}
                    >
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            About
                        </Typography>
                </Button>

                <Button 
                    color="inherit" 
                    size="small" 
                    sx={{
                        // marginLeft: '2%',
                        marginRight: '2%',
                        color:"#444444",
                        fontWeight: 'bold'
                    }}
                    onClick={() => history.push('/Contact')}
                    >
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Contact
                        </Typography>
                </Button>
                </Box>
            </Toolbar>
        </AppBar>
        </Box>
        </ThemeProvider>
    );
}
