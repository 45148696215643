import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'

import IML_container5 from './Thermochromic cup 5.mp4';
import IML_container6 from './Thermochromic cup 6.mp4';
import IML_container7 from './Thermochromic cup 7.mp4';
import IML_container8 from './Thermochromic cup 8.mp4';
import IML_container11 from './Thermochromic cup 11.mp4';

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function IMLLabels() {
    const pictures = [
        {name: "IMLC1", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/IML%20containers%201.JPG?raw=true" },
        {name: "IMLC", widthSc: "80%", heightSc: "auto",image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/IML%20containers.JPG?raw=true" },
        {name: "laser", widthSc: "80%", heightSc: "auto",image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/Laser.JPG?raw=true" },
        {name: "metallic", widthSc: "80%", heightSc: "auto",image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/Metallic.JPG?raw=true" },
        {name: "orange1", widthSc: "80%", heightSc: "auto",image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/Orange%20peel%201.JPG?raw=true" },
        {name: "orange", widthSc: "80%", heightSc: "auto",image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/Orange%20peel.JPG?raw=true" },
        {name: "transp", widthSc: "80%", heightSc: "auto",image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/Transparent.JPG?raw=true" },
        {name: "white", widthSc: "80%", heightSc: "auto",image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/White.JPG?raw=true'},
        {name: "DSC8", widthSc: "auto", heightSc: "35vh", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/DSC_0008.JPG?raw=true'},
        {name: "DSC14", widthSc: "auto", heightSc: "35vh",image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/DSC_0014.JPG?raw=true'},
        {name: "DSC22", widthSc: "80%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/DSC_0022.JPG?raw=true'}
                           ];

        const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                    IML Labels and Containers
                </Typography>

                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >
                    <img
                        src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/IML%20containers%20to%20Henry%201/IML%20containers%201.JPG?raw=true"}
                        onLoad={() => setFirstImgLoaded(true)}
                        style={{ display: "none" }}
                        alt="pic"
                    />
                    {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                        }
                    </Carousel>
                )}
                </Box>
                </div>
                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        In-mold labeling (IML) is a packaging process that produces a sleek, attractive, and durable package with
a label embedded directly into the plastic mold. This type of packaging is used for products such as
shampoo, food containers, and toothpaste tubes.
                    </Typography>
                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        With in-mold labeling, the label is placed in the mold
cavity before the molten plastic is injected in and fills the space to form the package. As the plastic
cools, the label and plastic bond together, creating a more durable, attractive, and cost-effective
package compared to traditional labels and packaging processes.
                    </Typography>
                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        Additionally, IML can provide more
efficient production speeds since the label can be inserted in the mold cavity prior to the molding
process. This helps to reduce the amount of time and cost needed to produce labels and allows for
larger runs of product packaging with less waste. With in-mold labeling, companies can achieve an
attractive, high-quality package while saving money, time, and labor costs.
                    </Typography>
                </Box>
                </div>
                
                <div
                    style={{
                        paddingTop: "15%",
                        display: 'flex',
                        justifyContent: 'space-between',
                        align: 'center',
                        width: '90%',
                        paddingLeft: '5%',
                        paddingRight: '5%',
                    }}
                >
                    <video width="40%" height="auto" controls>
                    <source src={IML_container5} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>

                    <video width="40%" height="auto" controls>
                    <source src={IML_container6} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>

                <div
                    style={{
                        paddingTop: "15%",
                        display: 'flex',
                        justifyContent: 'space-between',
                        align: 'center',
                        width: '90%',
                        paddingLeft: '5%',
                        paddingRight: '5%',
                    }}
                >
                    <video width="40%" height="auto" controls>
                    <source src={IML_container7} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>

                    <video width="40%" height="auto" controls>
                    <source src={IML_container8} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>

                    
                </div>
                
                <div
                    style={{
                        paddingTop: "15%",
                        // display: 'flex',
                        justifyContent: 'space-between',
                        align: 'center',
                        width: '90%',
                        paddingLeft: '5%',
                        paddingRight: '5%',
                    }}
                >
                    <video width="40%" height="auto" controls>
                    <source src={IML_container11} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>

               
            </Box>
            </ThemeProvider>
        </div>
    );
}
