import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'


import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function GlassPackaging() {
    const pictures = [
        {name: "g1", widthSc: "70%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/1.jpg?raw=true" },
        {name: "gb", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20Bottles.JPG?raw=true" },
        // {name: "gj10", widthSc: "40vw", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20jar%2010.jpg?raw=true" },
        // {name: "gj11", widthSc: "40vw", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20jar%2011.jpg?raw=true" },
        // {name: "gj12", widthSc: "40vw", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20jar%2012.jpg?raw=true" },
        // {name: "gj13", widthSc: "40vw", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20jar%2013.jpg?raw=true" },
        {name: "gj14", widthSc: "60%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20jar%2014.jpg?raw=true" },
        {name: "gj22", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20jar%2022.jpg?raw=true" },
        {name: "gj25", widthSc: "60%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20jar%2025.jpg?raw=true" }
        // {name: "gj15", widthSc: "40vw", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/Glass%20jar%2015.jpg?raw=true" },
                           ]; // how many more?

        const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                    Glass Packaging Materials
                </Typography>

                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >
                    <img
                        src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Glass%20containers/Glass%20containers%20to%20Makhjan/1.jpg?raw=true"}
                        onLoad={() => setFirstImgLoaded(true)}
                        style={{ display: "none" }}
                        alt="pic"
                    />
                    {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                        }
                    </Carousel>
                    )}
                </Box>
                </div>

                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We are the premier provider of high-quality glass packaging solutions. Our selection of products ranges
from jars, bottles, cups, and beakers to vials, ampoules, and other specialty containers. Our glass
containers are made from premium materials, ensuring the integrity of your product, and providing
excellent customer satisfaction.



                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        At ZL International, we take pride in providing our customers with top-notch products and service. Our
dedicated team of professionals has the expertise to help you find the perfect glass packaging for your
needs. From pharmaceutical industries to food service establishments, we have the resources to serve
our clients with the best solutions available.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        If you're looking for a reliable and cost-efficient provider of glass packaging materials, then look no
further than ZL International. Our commitment to quality, customer satisfaction, and easy ordering
makes us the perfect choice for all your glass packaging needs.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        Thank you for visiting ZL International - we look forward to serving you!
                    </Typography>
                </Box>
                </div>

                
                <Box
                    sx={{
                        paddingTop: '10%',
                        
                    }}
                >
                    
                </Box>

               

            </Box>
            </ThemeProvider>
        </div>
    );
}
