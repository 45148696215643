import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function InjectionMolding() {
    const pictures = [
        // {name: "DSC11", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/DSC_0011.JPG?raw=true" },
        // {name: "DSC12", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/DSC_0012.JPG?raw=true" },
        {name: "DSC19", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/DSC_0019.JPG?raw=true" },
        {name: "DSC12", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/DSC_0012.JPG?raw=true" },
        {name: "DSC45", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/DSC_0045.JPG?raw=true" }
        // {name: "pb", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20Bottles.JPG?raw=true" },
        // {name: "pb1", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%201.jpg?raw=true" },
        // {name: "pb2", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%202.jpg?raw=true" },
        // {name: "pb3", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%203.jpg?raw=true" },
        // {name: "pb4", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%204.jpg?raw=true" },
        // {name: "pb5", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%205.jpg?raw=true" },
        // {name: "pb6", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%206.jpg?raw=true" },
        // {name: "pb7", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%207.jpg?raw=true" },
        // {name: "pb8", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%208.jpg?raw=true" },
        // {name: "pb9", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/Plastic%20bottle%209.jpg?raw=true" },
                           ];

        const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                    Injection Molding Packaging Materials
                </Typography>

                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >
                    <img
                        src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20containers%20to%20Makhjan/DSC_0019.JPG?raw=true"}
                        onLoad={() => setFirstImgLoaded(true)}
                        style={{ display: "none" }}
                        alt="pic"
                    />
                    {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                        }
                    </Carousel>
                )}
                </Box>
                </div>
                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We are a leader in injection molded package materials with a mission of offering our customers the
highest quality products and personalized customer service. Our state-of-the-art injection molding
facility is one of the most technologically advanced in the industry, enabling us to produce superior
quality products for customers' specific packaging needs.




                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We offer a wide range of injection molded package materials to choose from, including durable plastic
containers, caps, and closures. Our advanced technologies enable us to produce additional custom
products depending on the materials and requirements requested by our customers.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We take customer service and satisfaction seriously, with a team of knowledgeable experts standing by
to answer any questions customers may have. Send us a message and let us work with you to find the
best injection molded package solutions for your needs!
                    </Typography>
                </Box>
                </div>

                <Box
                    sx={{
                        paddingTop: '10%',
                        
                    }}
                >
                    
                </Box>

                

            </Box>
            </ThemeProvider>
        </div>
    );
}
