import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'

//CHANGE to packaging materials

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function ThermalForming() {
    const pictures = [
        {name: "DSC16", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/DSC_0016.JPG?raw=true" },
        {name: "DSC21", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/DSC_0021.JPG?raw=true" },
        {name: "DSC24", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/DSC_0024.JPG?raw=true" },
        {name: "DSC28", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/DSC_0028.JPG?raw=true" },
        {name: "DSC31", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/DSC_0031.JPG?raw=true" },
        {name: "therm1", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/Thermal%201.jpg?raw=true" },
        {name: "therm2", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/Thermal%202.jpg?raw=true" },
        {name: "therm4", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/Thermal%204.jpg?raw=true" },
                           ];
        const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                    Thermal Forming Packaging Materials
                </Typography>

                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >
                    <img
                        src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Thermal%20forming%20containers%20to%20Makhjan/DSC_0016.JPG?raw=true"}
                        onLoad={() => setFirstImgLoaded(true)}
                        style={{ display: "none" }}
                        alt="pic"
                    />
                    {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                        }
                    </Carousel>
                )}
                </Box>
                </div>
                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We are your go-to source for all your thermal forming plastic needs. Whether you’re a large industrial
manufacturer or a small-scale hobbyist, we have the tools and expertise to meet your project needs.



                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We offer a variety of packages, from large production runs to single batches of materials. Our selection
includes a wide range of materials— from common raw materials such as PET, PVC, and PP to specialty
materials like Polypropylene Foam.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        Our staff of certified and experienced professionals ensures that your order is handled safely and
securely. We strive to provide the best customer service and product quality— guaranteed.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        For more information on our products, services, and pricing please contact us directly. Thank you for
choosing ZL International!
                    </Typography>
                </Box>
                </div>
                
                <Box
                    sx={{
                        paddingTop: '10%',
                        
                    }}
                >
                    
                </Box>

                

            </Box>
            </ThemeProvider>
        </div>
    );
}
