import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'


import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

 
export default function AluminumTubes() {
    const pictures = [
        {name: "metal1", widthSc: "40vw", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Metal%20Tubes/Metal%20Tubes/Metal%20Tubes%201.JPG?raw=true" },
        {name: "metal", widthSc: "40vw", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Metal%20Tubes/Metal%20Tubes/Metal%20Tubes.JPG?raw=true" }
                           ];

    const [firstImgLoaded, setFirstImgLoaded] = useState(false);


    return (
        <div>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >   
                <ThemeProvider theme={theme}>
                    
                    <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                        Aluminum Tubes
                    </Typography>
                <div
                style={{
                    justifyContent: "space-between",
                    
                }}>
                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >

                <img
                    src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Metal%20Tubes/Metal%20Tubes/Metal%20Tubes%201.JPG?raw=true"}
                    onLoad={() => setFirstImgLoaded(true)}
                    style={{ 
                        display: "none" ,
                        paddingTop: { xs: '50%', md: '5%' }, left:"10px", width:"40vw", height: "auto"
                    }}
                    alt="pic"
                />
                {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                    {
                        pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                    }
                    </Carousel>
                )}
                    
                </Box>
                </div>
                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <div>
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' }
                        }}
                    >
                        We are a leading provider of collapsible aluminum tubes for packaging materials. Our tubes offer an
efficient and secure way to store and transport your payloads, while our unique construction ensures
they can fill and crimp easily.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' }
                        }}
                    >
                        At ZL International, we understand how important product safety is, so our aluminum tubes are
manufactured to the highest quality standards. Our aluminum seal membrane are airtight and easy to
pierce, which ensures all your materials arrive in the same condition they were loaded in.
We also offer an extensive range of accessories to ensure you find the best solution for your
organization's needs. Whether you require a mini disposable or a larger carrying tube, ZL International
has the solution for you.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw,  15px)' },
                            color: "#333333"
                        }}
                    >
                        We understand that price is key in today's market, so we provide unbeatable prices that can't be found
anywhere else.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw,  15px)' },
                            color: "#333333"
                        }}
                    >
Why wait? Contact our team today and let us show you how ZL International can make a difference.
                    </Typography>
                    </div>
                    
                </Box>
                </div>
                </div>

                
                <Box
                    sx={{
                        paddingTop: '10%',
                        
                    }}
                >
                    
                </Box>

              
                </ThemeProvider>
            </Box>
        </div>
    );
}
