import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'


import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function PlasticTubes() {
    const pictures = [
        {name: "pts", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20Tubes.JPG?raw=true" },
        {name: "pts1", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20Tubes%201.JPG?raw=true" },
        {name: "pt15", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%2015.jpg?raw=true" },     
        {name: "pt19", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%2019.jpg?raw=true" },     
        {name: "pt30", widthSc: "70%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%2030.jpg?raw=true" },     
        {name: "pt33", widthSc: "70%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%2033.jpg?raw=true" },     
        {name: "pt38", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%2038.jpg?raw=true" },     
        {name: "pt60", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%2060.jpg?raw=true" },     
        // {name: "pt105", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20105.jpg?raw=true" },
        // {name: "pt106", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20106.jpg?raw=true" },
        // {name: "pt114", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20114.jpg?raw=true" },
        {name: "pt124", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20124.jpg?raw=true" },
        // {name: "pt131", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20131.jpg?raw=true" },
        // {name: "pt135", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20135.jpg?raw=true" },
        // {name: "pt154", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20154.jpg?raw=true" },
        // {name: "pt159", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20159.jpg?raw=true" },
        {name: "pt161", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20tube%20161.jpg?raw=true" },
                           ];
                           // how many more do we want?? 
        const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                    Plastic Tubes
                </Typography>

                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >
                    <img
                        src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Plastic%20tubes%20to%20Makhjan/Plastic%20Tubes.JPG?raw=true"}
                        onLoad={() => setFirstImgLoaded(true)}
                        style={{ display: "none" }}
                        resizeMode="contain"
                        alt="pic"
                    />
                    {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                        }
                    </Carousel>
                )}
                </Box>
                </div>
                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We offer a great selection of collapsible plastic tubes for all your packaging needs. Our collapsible tubes
offer a variety of benefits, including strong and durable plastic, easy transport, and great storage
options. Our tubes are ideal for business owners as well as home users looking for cost-effective
packaging solutions.


                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        Our tubes come in sizes and shapes to fit any type of product, so you are sure to find the perfect option
for your needs. We also offer custom printing and decorating services to create the perfect package for
your product.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        Our collapsible plastic tubes are lightweight and easy to store and transport, so your items will remain
safe and secure. And our competitive prices guarantee that you’ll get the best value for your money.
Take a look at our selection of collapsible plastic tubes, and don’t hesitate to contact us with any
questions. Thanks for choosing ZL International!
                    </Typography>
                </Box>
                </div>
                
                <Box
                    sx={{
                        paddingTop: '10%',
                        
                    }}
                >
                    
                </Box>

                

            </Box>
            </ThemeProvider>
        </div>
    );
}
