import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'


import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function BlowMolding() {
    const pictures = [{name: "pbs", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20Bottles.JPG?raw=true"},
        {name: "pb1", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%201.jpg?raw=true" },
        {name: "pb2", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%202.jpg?raw=true" },
        {name: "pb3", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%203.jpg?raw=true" },
        {name: "pb4", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%204.jpg?raw=true" },
        {name: "pb5", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%205.jpg?raw=true" },
        {name: "pb6", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%206.jpg?raw=true" },
        {name: "pb7", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%207.jpg?raw=true" },
        {name: "pb8", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%208.jpg?raw=true" },
        {name: "pb9", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottle%209.jpg?raw=true" },
        {name: "pb10", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20bottles%2010.JPG?raw=true" }]
    const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                    Blow Molding Packaging Materials
                </Typography>

                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >
                    <img
                        src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Blow%20Molding/Plastic%20Bottles.JPG?raw=true"}
                        onLoad={() => setFirstImgLoaded(true)}
                        style={{ display: "none" }}
                        alt="pic"
                    />
                    {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                        }
                    </Carousel>
                )}
                </Box>
                </div>
                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We are a full-service blow molding packaging materials supplier, providing high-quality and durable
products for a variety of industries. Our products are ideal for storing and transporting materials safely
and efficiently. We offer a wide variety of blow molding products, from standard containers to custom-
designed packaging solutions. 
                    </Typography>
                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        Our decades of experience in the industry have enabled us to develop
world-class production and quality assurance systems, allowing us to craft products that meet even the
most demanding requirements. No matter what your industrial needs may be, our dedicated team can
provide tailor-made solutions to meet your needs. 
                    </Typography>
                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        With competitive prices, quick delivery times, and
excellent customer service, you can rest assured that you will be getting the best value for your money.
Contact us today and discover why we’re the go-to source for all your blow molding packaging needs.
                    </Typography>
                </Box>
                </div>
                
                <Box
                    sx={{
                        paddingTop: '10%',
                        
                    }}
                >
                    
                </Box>


            </Box>
            </ThemeProvider>
        </div>
    );
}
