import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IML_process from './IML_process.mp4'
import Carousel from 'react-material-ui-carousel'

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function IMLAuto() {
    const pictures = [
        {name: "IMLA1", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Automation%20and%20mold%20images%20to%20Makhjan/IML%20automation%201.jpg?raw=true" },
  {name: "IMLA2", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Automation%20and%20mold%20images%20to%20Makhjan/IML%20automation%202.jpg?raw=true" },
  {name: "IMLA3", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Automation%20and%20mold%20images%20to%20Makhjan/IML%20automation%203.jpg?raw=true" },
  {name: "IMLA4", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Automation%20and%20mold%20images%20to%20Makhjan/IML%20automation%204.jpg?raw=true" },
  {name: "IMLM1", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Automation%20and%20mold%20images%20to%20Makhjan/IML%20mold%201.jpg?raw=true" },
  {name: "IMLM2", widthSc: "80%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Automation%20and%20mold%20images%20to%20Makhjan/IML%20mold%202.jpg?raw=true" }
                   ];

        const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                    IML Automation and Molds
                </Typography>

                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >
                    <img
                        src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Automation%20and%20mold%20images%20to%20Makhjan/IML%20automation%201.jpg?raw=true"}
                        onLoad={() => setFirstImgLoaded(true)}
                        style={{ display: "none" }}
                        alt="pic"
                    />
                    {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                        }
                    </Carousel>
                )}
                </Box>
                </div>
                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            // color: "white",
                            // fontFamily: "Lucida Console", "Courier New", monospace
                            color: "#333333"
                        }}
                    >
                        As the representative of leading IML Automation Equipment and matching molds, we specialize in
    providing cutting-edge technology that helps customers reduce their labor costs and improve their
    production times. Our extensive range of automation machines are designed to help companies
    minimize downtime and maximize production efficiency. 
                    </Typography>
                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            // color: "white",
                            // fontFamily: "Lucida Console", "Courier New", monospace
                            color: "#333333"
                        }}
                    >
                        From automatic molding robotics and
    assembly, our range of machines can help any customer achieve their automation goals. Each machine is
    designed with the customer in mind and is easy to use and maintain.
                    </Typography>
                    <Typography display="block"
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            // color: "white",
                            // fontFamily: "Lucida Console", "Courier New", monospace
                            color: "#333333"
                        }}
                    >
                         Our customer service team is
    always available to answer any questions or provide assistance with machines. We strive to ensure that
    our customers get the best automation machines for their needs, so please do not hesitate to contact
    us. Thank you for choosing IML Automation Equipment!
                    </Typography>
                </Box>
                </div>

                <div
                    style={{
                        align: "center",
                        paddingTop: "15%",
                    }}
                >
                    <video width="50%" height="auto" controls>
                    <source src={IML_process} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>
                
                <Box
                    sx={{
                        paddingTop: '10%',
                        
                    }}
                >
                    
                </Box>

               

            </Box>
            </ThemeProvider>
        </div>
    );
}
