import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Times New Roman',
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

export default function PaperPackaging() {
    const pictures = [
        {name: "bag1", widthSc: "60%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%201.jpg?raw=true" },
        // {name: "bag12", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%2012.jpg?raw=true" },
        {name: "bag14", widthSc: "50%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%2014.jpg?raw=true" },
        // {name: "bag2", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%202.jpg?raw=true" },
        {name: "bag20", widthSc: "60%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%2020.jpg?raw=true" },
        {name: "bag21", widthSc: "60%", heightSc: "auto", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%2021.jpg?raw=true" },
        // {name: "bag22", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%2022.jpg?raw=true" },
        // {name: "bag23", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%2023.jpg?raw=true" },
        // {name: "bag3", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%203.jpg?raw=true" },
        // {name: "bag9", widthSc: "auto", heightSc: "50vh", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%209.jpg?raw=true" },
        {name: "pouch18", widthSc: "60%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Pouch%2018.jpg?raw=true'},
        // {name: "pouch19", widthSc: "auto", heightSc: "50vh", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Pouch%2019.jpg?raw=true'},
        // {name: "pouch20", widthSc: "auto", heightSc: "50vh", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Pouch%2020.jpg?raw=true'},
        // {name: "pouch21", widthSc: "auto", heightSc: "50vh", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Pouch%2021.jpg?raw=true'},
        {name: "pouch22", widthSc: "40%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Pouch%2022.jpg?raw=true'},
        // {name: "PPM1", widthSc: "auto", heightSc: "50vh", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20pulp%20molding%20products/Paper%20pulp%20molding%20products%20to%20Makhjan/Paper%20pulp%20molding%20product%201.jpg?raw=true'},
        {name: "PPM5", widthSc: "40%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20pulp%20molding%20products/Paper%20pulp%20molding%20products%20to%20Makhjan/Paper%20pulp%20molding%20product%205.jpg?raw=true'},
        {name: "PPM9", widthSc: "60%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20pulp%20molding%20products/Paper%20pulp%20molding%20products%20to%20Makhjan/Paper%20pulp%20molding%20product%209.jpg?raw=true'},
        {name: "PPM10", widthSc: "60%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20pulp%20molding%20products/Paper%20pulp%20molding%20products%20to%20Makhjan/Paper%20pulp%20molding%20product%2010.jpg?raw=true'},
        {name: "PPM19", widthSc: "40%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20pulp%20molding%20products/Paper%20pulp%20molding%20products%20to%20Makhjan/Paper%20pulp%20molding%20product%2019.jpg?raw=true'},
        {name: "PPM23", widthSc: "40%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20pulp%20molding%20products/Paper%20pulp%20molding%20products%20to%20Makhjan/Paper%20pulp%20molding%20product%2023.jpg?raw=true'},
        {name: "PPM24", widthSc: "60%", heightSc: "auto", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20pulp%20molding%20products/Paper%20pulp%20molding%20products%20to%20Makhjan/Paper%20pulp%20molding%20product%2024.jpg?raw=true'}
        // {name: "PPM11", widthSc: "auto", heightSc: "50vh", image: 'https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20pulp%20molding%20products/Paper%20pulp%20molding%20products%20to%20Makhjan/Paper%20pulp%20molding%20product%2011.jpg?raw=true'}
                           ]; //how many more paper pulp molding?

        const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                        align='center'
                        position = 'relative'
                        top = '50px'
                        color= "#333333"
                        // marginTop = '3%'
                        sx={{
                            paddingTop:"3%",
                            paddingLeft: '3%',
                            color: "#333333",
                            fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 45px)' },
                        }}
                    >
                    Paper Packaging Materials
                </Typography>

                <div
                style = {{width: "50%",
                    float: "left",
                    padding: "20px"}}>                

                <Box
                    sx={{
                        paddingTop:'3%',
                        paddingLeft: '3%',
                    }}
                >
                    <img
                        src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/data/Paper%20packaging/Paper%20packaging%20to%20Makhjan/Bag%201.jpg?raw=true"}
                        onLoad={() => setFirstImgLoaded(true)}
                        style={{ display: "none" }}
                        alt="pic"
                    />
                    {firstImgLoaded && (
                    <Carousel
                        align = 'left'
                        width = '40vw'
                        height = "50vh"
                        swipe={false}
                        sx = {{
                            paddingTop: { xs: '20%', md: '6%' }
                        }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{left:"10px", width: item.widthSc, height: item.heightSc}}  /> )
                        }
                    </Carousel>
                )}
                </Box>
                </div>
                <div>
                <Box
                    sx={{
                        paddingRight: '3%',
                        paddingLeft: '50%',
                        paddingTop: { xs: '15%', md: '5%' }
                    }}
                >
                    <Typography 
                        align='left'
                        sx={{
                            // paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We are a leading provider of high-quality paper packaging materials for a wide range of businesses and
industries. Our team has decades of experience designing and manufacturing quality products to meet
your needs, from printed kit cartons to pulp molding trays and more.



                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We are proud to offer a variety of sustainable paper packaging solutions, including recycled and
compostable materials. Our team is committed to providing you with the best products and services
possible and will be there to assist you during every step of the process.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We understand that each of our customers have unique needs and take pride in our ability to create
custom solutions that meet your specifications. When you choose ZL International, you can rest assured
you're getting premium products without sacrificing your budget.
                    </Typography>

                    <Typography 
                        align='left'
                        sx={{
                            paddingTop:"3%",
                            // paddingRight: '3%',
                            // paddingLeft: '10%',
                            fontSize: { xs: 'clamp(8px, 1vw, 10px)', md: 'clamp(8px, 10vw, 15px)' },
                            color: "#333333"
                        }}
                    >
                        We'd love to learn more about your unique needs and help you get the perfect paper packaging
materials for your business. Please don't hesitate to get in touch if you have any questions or need
assistance finding the right solution. Thank you for considering ZL International and we look forward to
working with you!
                    </Typography>
                </Box>
                </div>
                
                <Box
                    sx={{
                        paddingTop: '10%',
                        
                    }}
                >
                    
                </Box>

               

            </Box>
            </ThemeProvider>
        </div>
    );
}
