import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'
import Contact from './email_pic.png';
import AboutUsPic from './cropped-about-us.png';
import ProductPic from './cropped_products.png';
import IconButton from '@mui/material/IconButton';

import history from './history';

import "@fontsource/montserrat";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            "Montserrat",
            "sans-serif",
          ].join(','),
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
  });

 


export default function HomePage() {
    const pictures = [
        {name: "bag1", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Bag%201.jpg?raw=true" },
        {name: "DSC0016", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/DSC_0016.JPG?raw=true" },
        {name: "DSC0019", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/DSC_0019.JPG?raw=true" },
        {name: "DSC0021", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/DSC_0021.JPG?raw=true" },
        {name: "glassBottles", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Glass%20Bottles.JPG?raw=true" },
        {name: "glassJar22", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Glass%20jar%2022.jpg?raw=true" },
        {name: "imlcont", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/IML%20containers.JPG?raw=true" },
        {name: "metalTubes", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Metal%20Tubes.JPG?raw=true" },
        {name: "paperPulp", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Paper%20pulp%20molding%20product%2027.jpg?raw=true" },
        {name: "plasticBottles", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Plastic%20Bottles.JPG?raw=true" },
        {name: "plasticTubes1", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Plastic%20Tubes%201.JPG?raw=true" },
        {name: "plasticTubes", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Plastic%20Tubes.JPG?raw=true" },
        {name: "plasticBottle9", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Plastic%20bottle%209.jpg?raw=true" },
        {name: "white", image: "https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/White.JPG?raw=true" }
                      ];

        const [firstImgLoaded, setFirstImgLoaded] = useState(false);

    console.log("Reached HomePage");
    return (
        
        <div>
            
            <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh', // use 100% instead if you want to set the height to the full viewport height
                    // marginTop:'5%',
                    backgroundColor: "#f5f2e3",
                }}
            >
                <Typography 
                    variant="h4"
                    align='center'
                    position = 'relative'
                    top = '50px'
                    color= "#333333"
                    marginTop = '3%'
                    marginLeft = '3%'
                    marginRight = '3%'
                    sx={{
                        fontSize: { xs: 'clamp(16px, 4vw, 32px)', md: 'clamp(16px, 5vw, 40px)' },
                        padding: { xs: '2rem 0', md: '2rem' },
                      }}
                >
                    Robust engineering solutions for the packaging market
                </Typography>

                
                <img
                    src={"https://github.com/lilyyma16/ZLIW_Images/blob/main/homepage/Bag%201.jpg?raw=true"}
                    onLoad={() => setFirstImgLoaded(true)}
                    style={{ display: "none" }}
                    alt="pic"
                />
                {firstImgLoaded && (
                    <Carousel
                        swipe={false}
                        style={{ width: '70%' }}
                    >
                        {
                            pictures.map( (item, i) => <img src = {item.image} alt = {item.name} style={{paddingTop: '5%', width:"40vw", height: "auto"}}  /> )
                        }
                    </Carousel>
                )}
                
                

                <div
                    style={{
                        justifyContent:'space-evenly',
                        paddingTop: '15%',
                        display:'flex',
                        position: 'relative',
                        paddingBottom: '5%'
                    }}
                >

                        
                    <div
                        
                    >
                        <IconButton
                            sx={{
                                height: { xs: '18vw', md: '18vw' },
                                width: { xs: '18vw', md: '18vw' },
                              }}
                            onClick={() => history.push('/IML_auto')}
                            disableRipple={true}
                        >
                            
                            <img src={ProductPic} alt="Product pic" 
                                height="100%"
                                width="100%"
                            />
                            
                        </IconButton>
                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                                
                            }}
                        >
                            Products
                        </Typography>
                    </div>
                    <div>
                        <IconButton
                            sx={{
                                height: { xs: '18vw', md: '18vw' },
                                width: { xs: '18vw', md: '18vw' },
                              }}
                            onClick={() => history.push('/AboutUs')}
                        >
                            
                            <img src={AboutUsPic} alt="AboutUs pic" 
                                height="100%"
                                width="100%"
                            />
                            
                        </IconButton>

                        <Typography 
                            sx={{
                                color: '#333333',
                                paddingTop:"3%",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                            }}
                        >
                            About Us
                        </Typography>
                    </div>
                        
                    <div>
                        <IconButton
                            sx={{
                                height: { xs: '18vw', md: '18vw' },
                                width: { xs: '18vw', md: '18vw' },
                              }}
                            onClick={() => history.push('/Contact')}
                        >
                            
                            <img src={Contact} alt="Contact pic" 
                                height="100%"
                                width="100%"
                            />
                            
                        </IconButton>

                        <Typography 
                            sx={{
                                paddingTop:"3%",
                                color: "#333333",
                                fontSize: { xs: 'clamp(8px, 1vw, 15px)', md: 'clamp(8px, 10vw, 15px)' }
                            }}
                        >
                            Contact
                        </Typography>
                    </div>

                </div>

                
            </Box>

            </ThemeProvider>
        </div>
    );
}
